import React, { useContext } from 'react'
import monetDuo from '../assets/img/photos/monetDuo.jpg'
import { IntlContext } from 'react-intl'

const BottomSection = () => {

    const localeCtx = useContext(IntlContext);

    let shortLang = localeCtx.locale;

    if (shortLang.indexOf('-') !== -1)
        shortLang = shortLang.split('-')[0];

    if (shortLang.indexOf('_') !== -1)
        shortLang = shortLang.split('_')[0];

  return (
    <div>
     <div className=" bg-center bg-cover h-screen "
        style={{ backgroundImage: `url(${monetDuo})` }}>

        <div className="flex justify-center items-start  h-full pt-28">
            <div className=' pt-10 pb-10 bg-[var(--background-opaque)] w-full flex justify-center items-start '>

            {
                shortLang === "fr"?
                <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1015.92 101.94"  className='max-w-5xl w-3/4 cursor-pointer'>

                <path class="cls-1" d="M68.71,34.78c0,18.97-15.38,34.35-34.35,34.35S0,53.75,0,34.78,15.38.42,34.35.42s34.35,15.38,34.35,34.35ZM57.06,26.74C50.85,9.18,35.64-1.46,23.1,2.98,10.56,7.41,5.43,25.25,11.65,42.81c6.21,17.56,21.42,28.2,33.96,23.77,12.54-4.44,17.67-22.27,11.45-39.83Z"/>
                <path class="cls-1" d="M112.33,69.02v-.09h-2.73c-3.64,0-6.59-2.94-6.6-6.58,0-3.32-.02-7.25-.04-10.18,0-12.64-4.14-19.53-10-21.2-3.31-.94-7.43-.37-12.32,2.55,1.53,2.01,2.7,4.86,2.7,9.64v18.99c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99h-9.43c-2.08,0-3.8-.66-5.15-1.99-1.35-1.32-2.03-2.95-2.03-4.87l-.3-38.43c0,5.03,4,5.91,6.91,9.45,15.27-10.94,26.27-3.14,30.4,7.52.63,1.64,1.55,6.12,1.55,13.68.03,3.13.06,6.57.08,9.33.26,1.26.85,2.37,1.79,3.33,1.3,1.33,2.99,1.99,5.07,1.99h-6.92Z"/>
                <path class="cls-1" d="M180.98,50.82c2.02,3.03,1.4,7.03.43,8.9l-.06.12-.02-.03c-1.36,2.3-3.74,4.46-6.47,5.93.15-.06.3-.11.44-.18-9.01,5.39-23.49,5.7-34.49-1.05l5.43-4.93c-.04-.11-.05-.17-.05-.17.03.04.07.09.1.13l2.58-2.34c3.17,5.41,11.28,10.64,20.07,9.94,1.16-.21,2.3-.51,3.4-.92.72-.27,1.43-.6,2.12-.97,5.3-2.86,4.78-7.19-.96-9.45-3.57-1.4-7.34-2.21-9.97-2.65-8.38-1.39-14.21-4.12-16.88-7.87-1.74-2.45-2.63-12.13,6.68-16.19,5.03-1.87,10.82-1.6,15.9-.07,8.22,2.62,13.24,7.95,13.24,12.4l-7.13.02c.95-2.65-.57-7.57-5.52-10.83-2.03-1.34-4.58-2.07-7.22-2.23-3.05-.19-6.14.17-8.97,1.22-.03.01-.07.02-.1.04-5.87,2.24-6.15,7.83-.39,10.24,3.31,1.39,6.82,2.16,9.29,2.57,9.55,1.59,15.97,4.47,18.55,8.34Z"/>
                <path class="cls-1" d="M198.53,57.54c4.7,7.49,11.42,11.59,16.24,9.4,2.73-1.24,4.34-4.28,4.75-8.22l1.24,4.49c-1.65,1.63-3.62,3.01-5.86,4.03-10.18,4.63-22.18.13-26.81-10.05-4.63-10.18-.13-22.18,10.05-26.81,9.81-4.46,21.31-.44,26.27,8.96l-.04.03s.06-.02.09-.04l.19.53c-1.1.39-2.14.84-3.11,1.33l-4.78,3.09s0,0,0,0c-2.23,1.75-4.13,3.69-5.91,5.5-3.76,3.82-7.19,7.32-12.32,7.75ZM210.45,49.4c1.82-1.86,3.77-3.84,6.07-5.63-4.59-9.75-12.69-15.59-18.23-13.07-5.63,2.56-6.5,12.75-1.95,22.76.57,1.25,1.2,2.44,1.87,3.56,5.04-.3,8.39-3.69,12.24-7.62Z"/>
                <path class="cls-1" d="M287.15,34.26l-6.87,6.63h-.22c-2.24-5.1-6.92-8.44-14.15-6.51-.95.53-1.8,1.18-2.55,1.93l.23,18.07h-.08v2.41c0,13.9-9.89,11.52-9.89,19.43l.12-24.15h.02v-16.91c0-1.92-.65-3.55-1.95-4.87-1.3-1.33-2.99-1.99-5.07-1.99h9.43c2.08,0,3.79.66,5.15,1.99s2.03,2.95,2.03,4.87v.32c.37-.33.75-.64,1.16-.93,1.73-1.51,8.28-6.69,15.63-5.11,2.96.98,5.28,2.68,7.02,4.81Z"/>
                <path class="cls-1" d="M299.66,57.54c4.7,7.49,11.42,11.59,16.24,9.4,2.73-1.24,4.34-4.28,4.75-8.22l1.24,4.49c-1.65,1.63-3.62,3.01-5.86,4.03-10.18,4.63-22.18.13-26.81-10.05-4.63-10.18-.13-22.18,10.05-26.81,9.81-4.46,21.31-.44,26.27,8.96l-.04.03s.06-.02.09-.04l.19.53c-1.1.39-2.14.84-3.11,1.33l-4.78,3.09s0,0,0,0c-2.23,1.75-4.13,3.69-5.91,5.5-3.76,3.82-7.19,7.32-12.32,7.75ZM311.57,49.4c1.82-1.86,3.77-3.84,6.07-5.63-4.59-9.75-12.69-15.59-18.23-13.07-5.63,2.56-6.5,12.75-1.95,22.76.57,1.25,1.2,2.44,1.87,3.56,5.04-.3,8.39-3.69,12.24-7.62Z"/>
                <path class="cls-1" d="M352.06,34.45v.57h-7.84c.18,1.1.29,1.78.29,3.19v24.08c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99h-9.43c-2.08,0-3.79-.66-5.15-1.99-1.35-1.33-2.03-2.95-2.03-4.87l-.19-27.27h-7.44v-.57h7.44l-.1-15.68c0,6.97,7.66,6.53,9.49,15.68h7.94Z"/>
                <path class="cls-1" d="M391.03,34.26l-6.87,6.63h-.22c-2.24-5.1-6.92-8.44-14.15-6.51-.95.53-1.8,1.18-2.55,1.93l.23,18.07h-.08v2.41c0,13.9-9.89,11.52-9.89,19.43l.12-24.15h.02v-16.91c0-1.92-.65-3.55-1.95-4.87-1.3-1.33-2.99-1.99-5.07-1.99h9.43c2.08,0,3.79.66,5.15,1.99,1.35,1.33,2.03,2.95,2.03,4.87v.32c.37-.33.75-.64,1.16-.93,1.73-1.51,8.28-6.69,15.63-5.11,2.96.98,5.28,2.68,7.02,4.81Z"/>
                <path class="cls-1" d="M419.91,67.65c-10.18,4.63-22.18.13-26.81-10.05-4.63-10.18-.13-22.18,10.05-26.81,10.18-4.63,22.18-.13,26.81,10.05,4.63,10.18.13,22.18-10.05,26.81ZM421.72,44.59c-4.55-10.01-12.8-16.05-18.43-13.49-5.63,2.56-6.5,12.75-1.95,22.76,4.55,10.01,12.8,16.05,18.43,13.49,5.63-2.56,6.5-12.75,1.95-22.76Z"/>
                <path class="cls-1" d="M482.99,69.15h-9.43c-2.08,0-3.8-.66-5.15-1.99-.94-.92-1.55-2-1.83-3.21-15.19,9.86-27.52,4.01-29.6-9.07-.38-2.42-.38-9.69-.38-9.69l-.16-21.34c0,7.91,9.89,5.54,9.89,19.43v.63c-1.07,15.99,4.04,20.18,9.84,21.83,2.86.81,6.31.06,10.34-2.24-.07-.39-.1-.8-.1-1.22l-.3-38.43c0,7.91,9.89,5.54,9.89,19.43v18.99c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99Z"/>
                <path class="cls-1" d="M523.17,28.66l-20.71,40.53-.25-.13-.02.04s-.03-.05-.05-.08l-.18-.09.05-.11c-7.8-12.58-21.82-19.96-21.82-40.37h.49v.02h9.37c-.64,17.97,13.12,24.03,16.59,31.26l16.02-31.35.5.26Z"/>
                <path class="cls-1" d="M534.43,57.54c4.7,7.49,11.42,11.59,16.24,9.4,2.73-1.24,4.34-4.28,4.75-8.22l1.24,4.49c-1.65,1.63-3.62,3.01-5.86,4.03-10.18,4.63-22.18.13-26.81-10.05-4.63-10.18-.13-22.18,10.05-26.81,9.81-4.46,21.31-.44,26.27,8.96l-.04.03s.06-.02.09-.04l.19.53c-1.1.39-2.14.84-3.11,1.33l-4.78,3.09s0,0,0,0c-2.23,1.75-4.13,3.69-5.91,5.5-3.76,3.82-7.19,7.32-12.32,7.75ZM546.35,49.4c1.82-1.86,3.77-3.84,6.07-5.63-4.59-9.75-12.69-15.59-18.23-13.07-5.63,2.56-6.5,12.75-1.95,22.76.57,1.25,1.2,2.44,1.87,3.56,5.04-.3,8.39-3.69,12.24-7.62Z"/>
                <path class="cls-1" d="M629.73,69.43h-9.43c-2.08,0-3.8-.66-5.15-1.99-1.35-1.33-2.03-2.95-2.03-4.87l.29-22.71c-.24,1.42-1.61,4.17-8.22,6.18-15.07,5.25-13.59,21.86.91,19.16,0,0-13.41,6.93-18.45,3.08-6.77-5.72-2.21-20.71,18.61-23.42,7.59-1.6,6.86-7.42,6.86-7.42,0-2.03-.87-4.91-2.17-6.21-1.25-1.15-3.41-1.73-5.69-1.79-6.96.3-9.92,6.74-10.12,10.17l-5.33-1.85c1.22-5.07,9.92-9.23,17.65-8.87,5.23.11,10.79,2.37,13.49,7.34,1.17,1.79,1.75,3.86,1.75,6.2v20.12c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99Z"/>
                <path class="cls-1" d="M675.25,69.15h-9.43c-2.08,0-3.8-.66-5.15-1.99-.94-.92-1.55-2-1.83-3.21-15.19,9.86-27.52,4.01-29.6-9.07-.38-2.42-.38-9.69-.38-9.69l-.16-21.34c0,7.91,9.89,5.54,9.89,19.43v.63c-1.07,15.99,4.04,20.18,9.84,21.83,2.86.81,6.31.06,10.34-2.24-.07-.39-.1-.8-.1-1.22l-.3-38.43c0,7.91,9.89,5.54,9.89,19.43v18.99c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99Z"/>
                <path class="cls-1" d="M770.82,69.15h-6.92v-.09h-2.73c-3.64,0-6.59-2.94-6.6-6.58,0-3.32-.02-7.25-.04-10.18,0-12.64-4.14-19.53-10-21.2-3.35-.95-7.51-.36-12.47,2.64,1.8,2.04,3.23,4.48,4.24,7.09.63,1.64,1.55,6.12,1.55,13.68.03,3.13.06,6.57.08,9.33.26,1.26.85,2.37,1.79,3.33,1.3,1.33,2.99,1.99,5.07,1.99h-6.92v-.09h-2.73c-3.64,0-6.59-2.94-6.6-6.58,0-3.32-.02-7.25-.04-10.18,0-12.64-4.14-19.53-10-21.2-3.31-.94-7.43-.37-12.32,2.55,1.53,2.01,2.7,4.87,2.7,9.65v18.99c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99h-9.43c-2.08,0-3.8-.66-5.15-1.99-1.35-1.33-2.03-2.95-2.03-4.87l-.3-38.43c0,5.03,4,5.91,6.91,9.45,11.34-8.12,20.31-5.92,25.88.11,15.36-11.11,26.41-3.29,30.55,7.41.63,1.64,1.55,6.12,1.55,13.68.03,3.13.06,6.57.08,9.33.26,1.26.85,2.37,1.79,3.33,1.3,1.33,2.99,1.99,5.07,1.99h0Z"/>
                <path class="cls-1" d="M846.4,34.41l-6.87,6.63h-.22c-2.24-5.1-6.92-8.44-14.15-6.51-.95.54-1.8,1.18-2.55,1.93l.23,18.07h-.08v2.41c0,13.89-9.89,11.52-9.89,19.43l.03-7.1h-7.84c-2.08,0-3.79-.66-5.15-1.99-1.35-1.32-2.03-2.95-2.03-4.87l.02-1.89c-5.01,4.19-10.39,7.31-16.48,8.1-3.41.85-6.94,1.1-9.02-.49-6.77-5.71-2.21-20.71,18.61-23.42,7.59-1.6,6.86-7.42,6.86-7.42,0-2.03-.87-4.9-2.17-6.21-1.25-1.15-3.41-1.73-5.69-1.79-6.96.31-9.92,6.74-10.12,10.17l-5.33-1.85c1.21-5.07,9.92-9.23,17.65-8.87,5.23.11,10.79,2.37,13.49,7.34,1.17,1.79,1.75,3.86,1.75,6.2v8.07c.9-.97,1.79-1.95,2.68-2.92.97-1.06,1.91-2.08,2.84-3.09v-9.02c0-1.92-.65-3.55-1.95-4.87-1.3-1.33-2.99-1.99-5.07-1.99h9.43c2.08,0,3.8.66,5.15,1.99,1.35,1.33,2.03,2.95,2.03,4.87v.32c.37-.33.75-.64,1.16-.93,1.73-1.51,8.28-6.69,15.63-5.11,2.96.98,5.28,2.68,7.02,4.81ZM797.92,59.77l.25-20.07c-.24,1.42-1.61,4.17-8.22,6.18-14.82,5.17-13.62,21.31.22,19.26,2.7-1.43,5.27-3.27,7.75-5.38ZM812.91,69.13l.08-16.9h.02v-7.06c-.8.86-1.6,1.74-2.43,2.64-1.03,1.13-2.06,2.25-3.1,3.37v11.23c0,1.92.65,3.55,1.95,4.87.95.97,2.11,1.58,3.48,1.84Z"/>
                <path class="cls-1" d="M852.85,24.12l-5.03-5.03,5.03-5.03,5.03,5.03-5.03,5.03ZM865.51,69.02h-9.43c-2.08,0-3.79-.66-5.15-1.99-1.35-1.32-2.03-2.95-2.03-4.87l-.3-37.86c0,7.91,9.89,5.54,9.89,19.43v18.43c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99Z"/>
                <path class="cls-1" d="M910.96,69.43h-9.43c-2.08,0-3.8-.66-5.15-1.99-1.35-1.33-2.03-2.95-2.03-4.87l.29-22.71c-.24,1.42-1.61,4.17-8.22,6.18-15.07,5.25-13.59,21.86.91,19.16,0,0-13.41,6.93-18.45,3.08-6.77-5.72-2.21-20.71,18.61-23.42,7.59-1.6,6.86-7.42,6.86-7.42,0-2.03-.87-4.91-2.17-6.21-1.25-1.15-3.41-1.73-5.69-1.79-6.96.3-9.92,6.74-10.12,10.17l-5.33-1.85c1.22-5.07,9.92-9.23,17.65-8.87,5.23.11,10.79,2.37,13.49,7.34,1.17,1.79,1.75,3.86,1.75,6.2v20.12c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99Z"/>
                <path class="cls-1" d="M935.63,99.91c-3.73,1.35-7.43,2.03-10.95,2.03-1.93,0-3.8-.2-5.58-.62-4.7-1.08-8.3-3.51-10.16-6.82-3.09-5.53-.68-12.32,6.45-18.27-2.94-.3-5.22-.71-6.25-1.47l7.04-5.97c.89-.84,1.87-1.74,2.94-2.7-3.05-2.02-5.59-4.9-7.22-8.47-4.63-10.18-.13-22.18,10.05-26.8,8.78-3.99,18.9-1.19,24.5,6.18,2.51-4.69,3.44-9.23,1.62-13.22l.52-.23c1.93,4.24.91,9.03-1.77,13.94.74,1.04,1.4,2.17,1.95,3.37,4.63,10.18.13,22.18-10.05,26.81-6.41,2.91-13.54,2.21-19.1-1.25-.94.85-1.82,1.64-2.63,2.4,3.6,1.79,18.19,1.01,26.48,4.31,11.27,4.49,16.12,20.33-7.84,26.79ZM942.01,80.97c-6.33-3.75-18.06-3.94-25.82-4.66-7.24,5.85-9.77,12.53-6.76,17.92,4.32,7.73,16.26,8.5,25.52,5.32,11.3-6.6,13.59-14.7,7.05-18.58ZM920.14,53.86c1.26,2.77,2.81,5.24,4.5,7.3,5.32-4.75,11.37-10.32,16.12-16.01-.08-.19-.16-.37-.24-.56-4.55-10.01-12.8-16.05-18.43-13.49-5.63,2.56-6.5,12.75-1.95,22.76ZM941.01,45.73c-4.75,5.66-10.74,11.17-16,15.87,4.35,5.09,9.61,7.55,13.57,5.75,5.42-2.46,6.43-11.99,2.44-21.62Z"/>
                <path class="cls-1" d="M966.85,57.54c4.7,7.49,11.42,11.59,16.24,9.4,2.73-1.24,4.34-4.28,4.75-8.22l1.24,4.49c-1.65,1.63-3.62,3.01-5.86,4.03-10.18,4.63-22.18.13-26.81-10.05-4.63-10.18-.13-22.18,10.05-26.81,9.81-4.46,21.31-.44,26.27,8.96l-.04.03s.06-.02.09-.04l.19.53c-1.1.39-2.14.84-3.11,1.33l-4.78,3.09s0,0,0,0c-2.23,1.75-4.13,3.69-5.91,5.5-3.76,3.82-7.19,7.32-12.32,7.75ZM978.77,49.4c1.82-1.86,3.77-3.84,6.07-5.63-4.59-9.75-12.69-15.59-18.23-13.07-5.63,2.56-6.5,12.75-1.95,22.76.57,1.25,1.2,2.44,1.87,3.56,5.04-.3,8.39-3.69,12.24-7.62Z"/>
                <path class="cls-1" d="M1010.83,73.31l-5.03-5.03,5.03-5.02,5.03,5.02-5.03,5.03ZM1005.99,59.51V0h9.93v59.51h-9.93Z"/>
              </svg>:<></>
            }
            {
            shortLang === "es"?
            <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 844.6 78"  className='max-w-5xl w-3/4 cursor-pointer'>
                <path class="cls-1" d="M73.25,3.89v67.89h-.57s-.01.02-.01.02c-.02-.09-.02-.2-.03-.29l-.18.39c.19-.19-45.92-66.57-45.92-67.01v67h-.41s0-67.72,0-67.92h-.56s11.18.03,11.18.03c-.15.15,25.52,37.04,35.93,52.1V3.89h.57Z"/>
                <path class="cls-1" d="M107.76,70.39c-10.18,4.63-22.18.13-26.81-10.05-4.63-10.18-.13-22.18,10.05-26.81,10.18-4.63,22.18-.13,26.81,10.05,4.63,10.18.13,22.18-10.05,26.81ZM109.57,47.33c-4.55-10.01-12.8-16.05-18.43-13.49-5.63,2.56-6.5,12.75-1.95,22.76,4.55,10.01,12.8,16.05,18.43,13.49,5.63-2.56,6.5-12.75,1.95-22.76Z"/>
                <path class="cls-1" d="M160.63,53.55c2.02,3.03,1.4,7.03.43,8.9l-.06.12-.02-.03c-1.36,2.3-3.74,4.46-6.47,5.93.15-.06.3-.11.44-.18-9.01,5.39-23.49,5.7-34.49-1.05l5.43-4.93c-.04-.11-.05-.17-.05-.17.03.04.07.09.1.13l2.58-2.34c3.17,5.41,11.28,10.64,20.07,9.94,1.16-.21,2.3-.51,3.4-.92.72-.27,1.43-.6,2.12-.97,5.3-2.86,4.78-7.19-.96-9.45-3.57-1.4-7.34-2.21-9.97-2.65-8.38-1.39-14.21-4.12-16.88-7.87-1.74-2.45-2.63-12.13,6.68-16.19,5.03-1.87,10.82-1.6,15.9-.07,8.22,2.62,13.24,7.95,13.24,12.4l-7.13.02c.95-2.65-.57-7.57-5.52-10.83-2.03-1.34-4.58-2.07-7.22-2.23-3.05-.19-6.14.17-8.97,1.22-.03.01-.07.02-.1.04-5.87,2.24-6.15,7.83-.39,10.24,3.31,1.39,6.82,2.16,9.29,2.57,9.55,1.59,15.97,4.47,18.55,8.34Z"/>
                <path class="cls-1" d="M230.06,31.39l-20.71,40.53-.25-.13-.02.04s-.03-.05-.05-.08l-.18-.09.05-.11c-7.8-12.58-21.82-19.96-21.82-40.37h.49v.02h9.37c-.64,17.97,13.12,24.03,16.59,31.26l16.02-31.35.5.26Z"/>
                <path class="cls-1" d="M241.33,60.28c4.7,7.49,11.42,11.59,16.24,9.4,2.73-1.24,4.34-4.28,4.75-8.22l1.24,4.49c-1.65,1.63-3.62,3.01-5.86,4.03-10.18,4.63-22.18.13-26.81-10.05-4.63-10.18-.13-22.18,10.05-26.81,9.81-4.46,21.31-.44,26.27,8.96l-.04.03s.06-.02.09-.04l.19.53c-1.1.39-2.14.84-3.11,1.33l-4.78,3.09s0,0,0,0c-2.23,1.75-4.13,3.69-5.91,5.5-3.76,3.82-7.19,7.32-12.32,7.75ZM253.25,52.14c1.82-1.86,3.77-3.84,6.07-5.63-4.59-9.75-12.69-15.59-18.23-13.07-5.63,2.56-6.5,12.75-1.95,22.76.57,1.25,1.2,2.44,1.87,3.56,5.04-.3,8.39-3.69,12.24-7.62Z"/>
                <path class="cls-1" d="M343.54,71.89h-6.92v-.09h-2.73c-3.64,0-6.59-2.94-6.6-6.58,0-3.32-.02-7.25-.04-10.18,0-12.64-4.14-19.53-10-21.2-3.35-.95-7.51-.36-12.47,2.64,1.8,2.04,3.23,4.48,4.24,7.09.63,1.64,1.55,6.12,1.55,13.68.03,3.13.06,6.57.08,9.33.26,1.26.85,2.37,1.79,3.33,1.3,1.33,2.99,1.99,5.07,1.99h-6.92v-.09h-2.73c-3.64,0-6.59-2.94-6.6-6.58,0-3.32-.02-7.25-.04-10.18,0-12.64-4.14-19.53-10-21.2-3.31-.94-7.43-.37-12.32,2.55,1.53,2.01,2.7,4.87,2.7,9.65v18.99c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99h-9.43c-2.08,0-3.8-.66-5.15-1.99-1.35-1.33-2.03-2.95-2.03-4.87l-.3-38.43c0,5.03,4,5.91,6.91,9.45,11.34-8.12,20.31-5.92,25.88.11,15.36-11.11,26.41-3.29,30.55,7.41.63,1.64,1.55,6.12,1.55,13.68.03,3.13.06,6.57.08,9.33.26,1.26.85,2.37,1.79,3.33,1.3,1.33,2.99,1.99,5.07,1.99h0Z"/>
                <path class="cls-1" d="M371.25,70.39c-10.18,4.63-22.18.13-26.81-10.05-4.63-10.18-.13-22.18,10.05-26.81,10.18-4.63,22.18-.13,26.81,10.05,4.63,10.18.13,22.18-10.05,26.81ZM373.05,47.33c-4.55-10.01-12.8-16.05-18.43-13.49-5.63,2.56-6.5,12.75-1.95,22.76,4.55,10.01,12.8,16.05,18.43,13.49s6.5-12.75,1.95-22.76Z"/>
                <path class="cls-1" d="M424.12,53.55c2.02,3.03,1.4,7.03.43,8.9l-.06.12-.02-.03c-1.36,2.3-3.74,4.46-6.47,5.93.15-.06.3-.11.44-.18-9.01,5.39-23.49,5.7-34.49-1.05l5.43-4.93c-.04-.11-.05-.17-.05-.17.03.04.07.09.1.13l2.58-2.34c3.17,5.41,11.28,10.64,20.07,9.94,1.16-.21,2.3-.51,3.4-.92.72-.27,1.43-.6,2.12-.97,5.3-2.86,4.78-7.19-.96-9.45-3.57-1.4-7.34-2.21-9.97-2.65-8.38-1.39-14.21-4.12-16.88-7.87-1.74-2.45-2.63-12.13,6.68-16.19,5.03-1.87,10.82-1.6,15.9-.07,8.22,2.62,13.24,7.95,13.24,12.4l-7.13.02c.95-2.65-.57-7.57-5.52-10.83-2.03-1.34-4.58-2.07-7.22-2.23-3.05-.19-6.14.17-8.97,1.22-.03.01-.07.02-.1.04-5.87,2.24-6.15,7.83-.39,10.24,3.31,1.39,6.82,2.16,9.29,2.57,9.55,1.59,15.97,4.47,18.55,8.34Z"/>
                <path class="cls-1" d="M463.49,60.28c4.7,7.49,11.42,11.59,16.24,9.4,2.73-1.24,4.34-4.28,4.75-8.22l1.24,4.49c-1.65,1.63-3.62,3.01-5.86,4.03-10.18,4.63-22.18.13-26.81-10.05-4.63-10.18-.13-22.18,10.05-26.81,9.81-4.46,21.31-.44,26.27,8.96l-.04.03s.06-.02.09-.04l.19.53c-1.1.39-2.14.84-3.11,1.33l-4.78,3.09s0,0,0,0c-2.23,1.75-4.13,3.69-5.91,5.5-3.76,3.82-7.19,7.32-12.32,7.75ZM475.4,52.14c1.82-1.86,3.77-3.84,6.07-5.63-4.59-9.75-12.69-15.59-18.23-13.07-5.63,2.56-6.5,12.75-1.95,22.76.57,1.25,1.2,2.44,1.87,3.56,5.04-.3,8.39-3.69,12.24-7.62Z"/>
                <path class="cls-1" d="M532.74,71.76v-.09h-2.73c-3.64,0-6.59-2.94-6.6-6.58,0-3.32-.02-7.25-.04-10.18,0-12.64-4.14-19.53-10-21.2-3.31-.94-7.43-.37-12.32,2.55,1.53,2.01,2.7,4.86,2.7,9.64v18.99c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99h-9.43c-2.08,0-3.8-.66-5.15-1.99-1.35-1.32-2.03-2.95-2.03-4.87l-.3-38.43c0,5.03,4,5.91,6.91,9.45,15.27-10.94,26.27-3.14,30.4,7.52.63,1.64,1.55,6.12,1.55,13.68.03,3.13.06,6.57.08,9.33.26,1.26.85,2.37,1.79,3.33,1.3,1.33,2.99,1.99,5.07,1.99h-6.92Z"/>
                <path class="cls-1" d="M580.56,71.76h-9.43c-2.08,0-3.79-.66-5.15-1.99-1.35-1.32-2.03-2.95-2.03-4.87l-.3-61.06c0,7.91,9.89,5.54,9.89,19.43v41.63c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99Z"/>
                <path class="cls-1" d="M626.13,72.17h-9.43c-2.08,0-3.8-.66-5.15-1.99-1.35-1.33-2.03-2.95-2.03-4.87l.29-22.71c-.24,1.42-1.61,4.17-8.22,6.18-15.07,5.25-13.59,21.86.91,19.16,0,0-13.41,6.93-18.45,3.08-6.77-5.72-2.21-20.71,18.61-23.42,7.59-1.6,6.86-7.42,6.86-7.42,0-2.03-.87-4.91-2.17-6.21-1.25-1.15-3.41-1.73-5.69-1.79-6.96.3-9.92,6.74-10.12,10.17l-5.33-1.85c1.22-5.07,9.92-9.23,17.65-8.87,5.23.11,10.79,2.37,13.49,7.34,1.17,1.79,1.75,3.86,1.75,6.2v20.12c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99Z"/>
                <path class="cls-1" d="M676.54,70.61c-7.35,1.58-13.9-3.59-15.63-5.11-.46-.32-.89-.67-1.29-1.05v.44c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99h-9.43c-2.08,0-3.8-.66-5.15-1.99-1.35-1.32-2.03-2.95-2.03-4.87l-.11-22.14s-.07.06-.11.1c.04-.04.08-.07.11-.1l-.19-38.92c0,7.91,9.89,5.54,9.89,19.43v12.18c26.85-14.61,38.06,28.14,16.92,35.16ZM659.62,36.41v27.19c.78.82,1.68,1.52,2.7,2.09,22.39,5.98,20.26-38.64-2.7-29.28Z"/>
                <path class="cls-1" d="M719.96,70.39c-10.18,4.63-22.18.13-26.81-10.05-4.63-10.18-.13-22.18,10.05-26.81,10.18-4.63,22.18-.13,26.81,10.05,4.63,10.18.13,22.18-10.05,26.81ZM721.76,47.33c-4.55-10.01-12.8-16.05-18.43-13.49-5.63,2.56-6.5,12.75-1.95,22.76,4.55,10.01,12.8,16.05,18.43,13.49,5.63-2.56,6.5-12.75,1.95-22.76Z"/>
                <path class="cls-1" d="M780.04,71.76h-9.43c-2.08,0-3.79-.66-5.15-1.99-1.35-1.33-2.03-2.95-2.03-4.87v-.32c-.37.33-.75.64-1.16.93-1.73,1.52-8.28,6.68-15.63,5.11-21.07-7-10.01-49.49,16.64-35.32l-.15-31.47c0,7.91,9.89,5.54,9.89,19.44v19.26c.12.11.23.21.35.33-.12-.11-.24-.2-.35-.31v22.35c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99ZM763.43,63.74l-.13-27.44c-22.71-8.89-24.73,35.34-2.43,29.39.96-.54,1.82-1.19,2.57-1.95Z"/>
                <path class="cls-1" d="M825.62,72.17h-9.43c-2.08,0-3.8-.66-5.15-1.99-1.35-1.33-2.03-2.95-2.03-4.87l.29-22.71c-.24,1.42-1.61,4.17-8.22,6.18-15.07,5.25-13.59,21.86.91,19.16,0,0-13.41,6.93-18.45,3.08-6.77-5.72-2.21-20.71,18.61-23.42,7.59-1.6,6.86-7.42,6.86-7.42,0-2.03-.87-4.91-2.17-6.21-1.25-1.15-3.41-1.73-5.69-1.79-6.96.3-9.92,6.74-10.12,10.17l-5.33-1.85c1.22-5.07,9.92-9.23,17.65-8.87,5.23.11,10.79,2.37,13.49,7.34,1.17,1.79,1.75,3.86,1.75,6.2v20.12c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99Z"/>
                <path class="cls-1" d="M839.5,78l-5.03-5.03,5.03-5.02,5.03,5.02-5.03,5.03ZM834.67,64.21V4.69h9.93v59.51h-9.93Z"/>
                <path class="cls-1" d="M5.09,0l5.03,5.03-5.03,5.02L.07,5.03,5.09,0ZM9.93,13.8v59.51H0V13.8h9.93Z"/>
              </svg>:<></>
            }
            {
                shortLang === "en"?<svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 876.63 103.4" className='max-w-5xl w-3/4 cursor-pointer'  >
                <path class="cls-1" d="M44.83,26.63c1.81-5.75-1.58-17.96-13.26-22.91-5.6-1.31-11.55-1.09-16.85,1.4-.04.02-.09.04-.13.06-8.05,3.88-8.43,13.56-.53,17.74,4.54,2.4,9.35,3.74,12.73,4.45,13.09,2.75,21.89,7.75,25.43,14.45,2.77,5.25,1.92,12.18.58,15.42l-.09.21-.03-.05c-2.35,5.02-6.93,9.68-11.9,12.02-2.64,1.24-5.44,1.94-8.28,2.18-2.79.24-5.61.02-8.37-.55C11.17,69.38.21,62.11,0,51.14l9.7-1.16c-.9,5.54,3.49,15.99,14.02,19.99,5.54,1.26,11.41,1.02,16.66-1.44.99-.47,1.97-1.03,2.91-1.67,6.63-4.52,6.08-18.86-1.08-22.48-4.96-2.5-10.24-3.94-13.91-4.71-11.48-2.41-19.49-7.13-23.14-13.63C2.77,21.79,1.55,11.25,14.31,4.23c7.3-3.43,15.75-2.72,22.99.35.99.5,1.9,1,2.75,1.51,9.29,4.35,15.68,11.87,14.55,20.8l-9.77-.26Z"/>
                <path class="cls-1" d="M70.73,59c4.7,7.49,11.42,11.59,16.24,9.4,2.73-1.24,4.34-4.28,4.75-8.22l1.24,4.49c-1.65,1.63-3.62,3.01-5.86,4.03-10.18,4.63-22.18.13-26.81-10.05-4.63-10.18-.13-22.18,10.05-26.81,9.81-4.46,21.31-.44,26.27,8.96l-.04.03s.06-.02.09-.04l.19.53c-1.1.39-2.14.84-3.11,1.33l-4.78,3.09s0,0,0,0c-2.23,1.75-4.13,3.69-5.91,5.5-3.76,3.82-7.19,7.32-12.32,7.75ZM82.65,50.86c1.82-1.86,3.77-3.84,6.07-5.63-4.59-9.75-12.69-15.59-18.23-13.07-5.63,2.56-6.5,12.75-1.95,22.76.57,1.25,1.2,2.44,1.87,3.56,5.04-.3,8.39-3.69,12.24-7.62Z"/>
                <path class="cls-1" d="M111.93,59c4.7,7.49,11.42,11.59,16.24,9.4,2.73-1.24,4.34-4.28,4.75-8.22l1.24,4.49c-1.65,1.63-3.62,3.01-5.86,4.03-10.18,4.63-22.18.13-26.81-10.05-4.63-10.18-.13-22.18,10.05-26.81,9.81-4.46,21.31-.44,26.27,8.96l-.04.03s.06-.02.09-.04l.19.53c-1.1.39-2.14.84-3.11,1.33l-4.78,3.09s0,0,0,0c-2.23,1.75-4.13,3.69-5.91,5.5-3.76,3.82-7.19,7.32-12.32,7.75ZM123.85,50.86c1.82-1.86,3.77-3.84,6.07-5.63-4.59-9.75-12.69-15.59-18.23-13.07-5.63,2.56-6.5,12.75-1.95,22.76.57,1.25,1.2,2.44,1.87,3.56,5.04-.3,8.39-3.69,12.24-7.62Z"/>
                <path class="cls-1" d="M204.96,30.12l-19.57,38.3c-9.34,24.41-18.57,27.9-27.86,20.01-7.49-10.11,11.62-20.36,25.57-19.22-7.91-11.94-21.13-19.47-21.13-39.29h.49v.03h9.37c-.64,17.97,13.12,24.03,16.59,31.26l16.02-31.35.5.26ZM183.89,70.47l-.14-.07.05-.11c-.21-.34-.43-.68-.65-1.02-12.9-.3-23.74,6.02-21.31,12.54,1.14,3.07,3.98,5.19,7.22,5.7,6.17.98,10.26-6.69,14.83-17.05Z"/>
                <path class="cls-1" d="M232.58,69.11c-10.18,4.63-22.18.13-26.81-10.05-4.63-10.18-.13-22.18,10.05-26.81,10.18-4.63,22.18-.13,26.81,10.05,4.63,10.18.13,22.18-10.05,26.81ZM234.39,46.05c-4.55-10.01-12.8-16.05-18.43-13.49-5.63,2.56-6.5,12.75-1.95,22.76,4.55,10.01,12.8,16.05,18.43,13.49,5.63-2.56,6.5-12.75,1.95-22.76Z"/>
                <path class="cls-1" d="M295.66,70.61h-9.43c-2.08,0-3.8-.66-5.15-1.99-.94-.92-1.55-2-1.83-3.21-15.19,9.86-27.52,4.01-29.6-9.07-.38-2.42-.38-9.69-.38-9.69l-.16-21.34c0,7.91,9.89,5.54,9.89,19.43v.63c-1.07,15.99,4.04,20.18,9.84,21.83,2.86.81,6.31.06,10.34-2.24-.07-.39-.1-.8-.1-1.22l-.3-38.43c0,7.91,9.89,5.54,9.89,19.43v18.99c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99Z"/>
                <path class="cls-1" d="M362.5,70.89h-9.43c-2.08,0-3.8-.66-5.15-1.99-1.35-1.33-2.03-2.95-2.03-4.87l.29-22.71c-.24,1.42-1.61,4.17-8.22,6.18-15.07,5.25-13.59,21.86.91,19.16,0,0-13.41,6.93-18.45,3.08-6.77-5.72-2.21-20.71,18.61-23.42,7.59-1.6,6.86-7.42,6.86-7.42,0-2.03-.87-4.91-2.17-6.21-1.25-1.15-3.41-1.73-5.69-1.79-6.96.3-9.92,6.74-10.12,10.17l-5.33-1.85c1.22-5.07,9.92-9.23,17.65-8.87,5.23.11,10.79,2.37,13.49,7.34,1.17,1.79,1.75,3.86,1.75,6.2v20.12c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99Z"/>
                <path class="cls-1" d="M383.86,35.91v.57h-7.84c.18,1.1.29,1.78.29,3.19v24.08c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99h-9.43c-2.08,0-3.79-.66-5.15-1.99-1.35-1.33-2.03-2.95-2.03-4.87l-.19-27.27h-7.44v-.57h7.44l-.1-15.68c0,6.97,7.66,6.53,9.49,15.68h7.94Z"/>
                <path class="cls-1" d="M431.7,35.91v.57h-7.84c.18,1.1.29,1.78.29,3.19v24.08c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99h-9.43c-2.08,0-3.79-.66-5.15-1.99-1.35-1.33-2.03-2.95-2.03-4.87l-.19-27.27h-7.44v-.57h7.44l-.1-15.68c0,6.97,7.66,6.53,9.49,15.68h7.94Z"/>
                <path class="cls-1" d="M474.51,70.48v-.09h-2.73c-3.64,0-6.59-2.94-6.6-6.58,0-3.32-.02-7.25-.04-10.18,0-12.64-4.14-19.53-10-21.2-2.68-.76-5.9-.53-9.62,1.14v30.05c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99h-9.43c-2.08,0-3.8-.66-5.15-1.99-1.35-1.32-2.03-2.95-2.03-4.87l-.3-61.06c0,7.91,9.89,5.54,9.89,19.43v10.72c13.72-7.96,23.57-.5,27.42,9.45.64,1.64,1.55,6.12,1.55,13.68.03,3.13.06,6.57.08,9.33.26,1.26.85,2.37,1.79,3.33,1.3,1.33,2.99,1.99,5.07,1.99h-6.92Z"/>
                <path class="cls-1" d="M492.64,59c4.7,7.49,11.42,11.59,16.24,9.4,2.73-1.24,4.34-4.28,4.75-8.22l1.24,4.49c-1.65,1.63-3.62,3.01-5.86,4.03-10.18,4.63-22.18.13-26.81-10.05-4.63-10.18-.13-22.18,10.05-26.81,9.81-4.46,21.31-.44,26.27,8.96l-.04.03s.06-.02.09-.04l.19.53c-1.1.39-2.14.84-3.11,1.33l-4.78,3.09s0,0,0,0c-2.23,1.75-4.13,3.69-5.91,5.5-3.76,3.82-7.19,7.32-12.32,7.75ZM504.56,50.86c1.82-1.86,3.77-3.84,6.07-5.63-4.59-9.75-12.69-15.59-18.23-13.07-5.63,2.56-6.5,12.75-1.95,22.76.57,1.25,1.2,2.44,1.87,3.56,5.04-.3,8.39-3.69,12.24-7.62Z"/>
                <path class="cls-1" d="M616.81,30.15l-20.68,40.5-.25-.13-.02.04s-.03-.05-.05-.08l-.18-.09.05-.1c-5.43-8.75-13.84-15-18.47-24.84l-12.2,25.2-.26-.12-.02.04s-.03-.05-.05-.08l-.18-.09.06-.11c-7.8-12.58-21.81-19.95-21.81-40.36l.49.02h9.37c-.63,17.76,12.81,23.89,16.46,31.02l7.83-16.17s-.04-.08-.06-.12l6.71-14.72h.18c-.64,17.96,13.11,24.03,16.59,31.26l15.99-31.32.5.26Z"/>
                <path class="cls-1" d="M628.06,59c4.7,7.49,11.42,11.59,16.24,9.4,2.73-1.24,4.34-4.28,4.75-8.22l1.24,4.49c-1.65,1.63-3.62,3.01-5.86,4.03-10.18,4.63-22.18.13-26.81-10.05-4.63-10.18-.13-22.18,10.05-26.81,9.81-4.46,21.31-.44,26.27,8.96l-.04.03s.06-.02.09-.04l.19.53c-1.1.39-2.14.84-3.11,1.33l-4.78,3.09s0,0,0,0c-2.23,1.75-4.13,3.69-5.91,5.5-3.76,3.82-7.19,7.32-12.32,7.75ZM639.98,50.86c1.82-1.86,3.77-3.84,6.07-5.63-4.59-9.75-12.69-15.59-18.23-13.07-5.63,2.56-6.5,12.75-1.95,22.76.57,1.25,1.2,2.44,1.87,3.56,5.04-.3,8.39-3.69,12.24-7.62Z"/>
                <path class="cls-1" d="M701.57,70.48h-9.43c-2.08,0-3.79-.66-5.15-1.99-1.35-1.33-2.03-2.95-2.03-4.87v-.32c-.37.33-.75.64-1.16.93-1.73,1.52-8.28,6.68-15.63,5.11-21.07-7-10.01-49.49,16.64-35.32l-.15-31.47c0,7.91,9.89,5.54,9.89,19.44v19.26c.12.11.23.21.35.33-.12-.11-.24-.2-.35-.31v22.35c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99ZM684.95,62.46l-.13-27.44c-22.71-8.89-24.73,35.34-2.43,29.39.96-.54,1.82-1.19,2.57-1.95Z"/>
                <path class="cls-1" d="M747.17,70.48h-9.43c-2.08,0-3.79-.66-5.15-1.99-1.35-1.33-2.03-2.95-2.03-4.87v-.32c-.37.33-.75.64-1.16.93-1.73,1.52-8.28,6.68-15.63,5.11-21.07-7-10.01-49.49,16.64-35.32l-.15-31.47c0,7.91,9.89,5.54,9.89,19.44v19.26c.12.11.23.21.35.33-.12-.11-.24-.2-.35-.31v22.35c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99ZM730.56,62.46l-.13-27.44c-22.71-8.89-24.73,35.34-2.43,29.39.96-.54,1.82-1.19,2.57-1.95Z"/>
                <path class="cls-1" d="M752.25,25.58l-5.03-5.03,5.03-5.03,5.03,5.03-5.03,5.03ZM764.91,70.48h-9.43c-2.08,0-3.79-.66-5.15-1.99-1.35-1.32-2.03-2.95-2.03-4.87l-.3-37.86c0,7.91,9.89,5.54,9.89,19.43v18.43c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99Z"/>
                <path class="cls-1" d="M804.56,70.48v-.09h-2.73c-3.64,0-6.59-2.94-6.6-6.58,0-3.32-.02-7.25-.04-10.18,0-12.64-4.14-19.53-10-21.2-3.31-.94-7.43-.37-12.32,2.55,1.53,2.01,2.7,4.86,2.7,9.64v18.99c0,1.92.65,3.55,1.95,4.87,1.3,1.33,2.99,1.99,5.07,1.99h-9.43c-2.08,0-3.8-.66-5.15-1.99-1.35-1.32-2.03-2.95-2.03-4.87l-.3-38.43c0,5.03,4,5.91,6.91,9.45,15.27-10.94,26.27-3.14,30.4,7.52.63,1.64,1.55,6.12,1.55,13.68.03,3.13.06,6.57.08,9.33.26,1.26.85,2.37,1.79,3.33,1.3,1.33,2.99,1.99,5.07,1.99h-6.92Z"/>
                <path class="cls-1" d="M835.82,101.37c-3.73,1.35-7.43,2.03-10.95,2.03-1.93,0-3.8-.2-5.58-.62-4.7-1.08-8.3-3.51-10.16-6.82-3.09-5.53-.68-12.32,6.45-18.27-2.94-.3-5.22-.71-6.25-1.47l7.04-5.97c.89-.84,1.87-1.74,2.94-2.7-3.05-2.02-5.59-4.9-7.22-8.47-4.63-10.18-.13-22.18,10.05-26.8,8.78-3.99,18.9-1.19,24.5,6.18,2.51-4.69,3.44-9.23,1.62-13.22l.52-.23c1.93,4.24.91,9.03-1.77,13.94.74,1.04,1.4,2.17,1.95,3.37,4.63,10.18.13,22.18-10.05,26.81-6.41,2.91-13.54,2.21-19.1-1.25-.94.85-1.82,1.64-2.63,2.4,3.6,1.79,18.19,1.01,26.48,4.31,11.27,4.49,16.12,20.33-7.84,26.79ZM842.2,82.43c-6.33-3.75-18.06-3.94-25.82-4.66-7.24,5.85-9.77,12.53-6.76,17.92,4.32,7.73,16.26,8.5,25.52,5.32,11.3-6.6,13.59-14.7,7.05-18.58ZM820.33,55.32c1.26,2.77,2.81,5.24,4.5,7.3,5.32-4.75,11.37-10.32,16.12-16.01-.08-.19-.16-.37-.24-.56-4.55-10.01-12.8-16.05-18.43-13.49-5.63,2.56-6.5,12.75-1.95,22.76ZM841.2,47.19c-4.75,5.66-10.74,11.17-16,15.87,4.35,5.09,9.61,7.55,13.57,5.75,5.42-2.46,6.43-11.99,2.44-21.62Z"/>
                <path class="cls-1" d="M871.53,73.31l-5.03-5.03,5.03-5.02,5.03,5.02-5.03,5.03ZM866.7,59.51V0h9.93v59.51h-9.93Z"/>
              </svg>:<></>
            }
            </div>

        </div>
       
    </div>
    
    </div>
  )
}

export default BottomSection
